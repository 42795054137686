import generateQuery from '@/libs/generate-query'
import HttpRequest from './HttpRequest'

class CategoriesProvider extends HttpRequest {
  findAll () {
    return this.get('/category')
  }

  createGroup (payload) {
    return this.post('/category', payload)
  }

  updateGroup (id, payload) {
    return this.patch(`/category/${id}`, payload)
  }

  deleteGroup (id) {
    return this.delete(`/category/${id}`)
  }

  paginate (page = 1, limit = 10, query) {
    return this.get(`/category/all/paginate?page=${page}&limit=${limit}${generateQuery(query)}`)
  }
}

export default CategoriesProvider
